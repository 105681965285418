.scrolling-background-container {
    display: flex;
    max-height: 1000px;
    height: 50%;
    /* flex-direction: column; */
    align-items: center;
}

.scrolling-component-container {
    margin-top: 15%;
    padding-left: 3%;
    padding-bottom: 20%;
    overflow: hidden;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}

.scrolling-component-container > h1 {
    font-size: xx-large;
}

.social-media-box > a {
    margin-right: 3%;
}

.social-media-box > a > img {
    height: 30px;
}

#get-resume-button {
    width: 150px;
    height: 36.5px;
    margin-top: 10%;
    background-color: white;
    /* position: absolute;
    top: 50%;
    left: 50%; */
}