h1 { 
    font-family: Verdana, Geneva, sans-serif; 
    font-size: 24px; 
    font-style: normal; 
    font-variant: normal; 
    font-weight: 700; 
    line-height: 26.4px; 
} 

h2 { 
    font-family: Verdana, Geneva, sans-serif; 
    font-size: 18px; 
    font-style: normal;
    font-variant: normal;
    font-weight: 600; 
    line-height: 18px; 
} 
    
h3 { 
    font-family: Verdana, Geneva, sans-serif; 
    font-size: 14px; font-style: normal; 
    font-variant: normal; 
    font-weight: 700; 
    line-height: 15.4px; 
} 

p { 
    font-family: Verdana, 
    Geneva, sans-serif; 
    font-size: 14px; 
    font-style: normal; 
    font-variant: normal; 
    font-weight: 400; 
    line-height: 20px; 
} 

blockquote { 
    font-family: Verdana, Geneva, sans-serif; 
    font-size: 21px; 
    font-style: normal; 
    font-variant: normal; 
    font-weight: 400; 
    line-height: 30px; } 
    
pre { 
    font-family: Verdana, Geneva, sans-serif; 
    font-size: 13px; 
    font-style: normal; 
    font-variant: normal; 
    font-weight: 400; 
    line-height: 18.5714px; 
}