.portfolio-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5%;
    margin-bottom: 8%;
    min-height: 800px;
}

.portfolio-container > h1 {
    font-size: 25px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #292929;
    font-weight: 600;
    font-family: 'Poppins', helvetica;
}

.portfolio-tiles-container{
    display: flex;
    flex-wrap: wrap;
    width: 75%;
    justify-content: space-evenly;
}

.portfolio-buttons-container {
    margin-bottom: 2%;
    margin-top: 3%;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.filter-buttons-container {
    padding-top: 75px;
    padding-bottom: 75px;
    width: 90%;
    display: flex;
    justify-content: center;
}

.filter-buttons-container > div {
    flex-wrap: wrap;
    row-gap: 10px;
}

.filter-buttons-container > div > button {
    margin-left: 16px;
}

.portfolio-filter-button {
    border-style: none;
    background-color: transparent;
    cursor: pointer;
    text-transform: uppercase;
    padding: 10px 15px 8px 15px;
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    color: #999999;
}
