.project-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2%;
    margin-bottom: 2%;
}

.project-image-container {
    display: flex;
    height: 300px;
}

.project-image-container > img {
    max-width: 100%;
    max-height: 100%;
}

.project-details-container {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    margin-top: 2%;
}

.vertical-separator {
    border-left: 3px solid black;
}

.project-links-container, .tech-stack-details-container, .project-description-container {
    width: 30%;
}

.status-and-circle-container {
    display: flex;
    align-items: center;
}

@media only screen and (max-width: 1120px) {
    .project-details-container {
        flex-direction: column;
        align-items: center;
    }

    .vertical-separator {
        border-top: 3px solid black;
        margin-top: 5%;
        margin-bottom: 2%;
        width: 90%;
    }

    .project-links-container, .tech-stack-details-container, .project-description-container {
        width: 90%;
    }
}

#go-back-button {
    align-self: flex-start;
    margin-left: 3%;
}
