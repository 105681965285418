.about-section-container{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 5%;
}

.about-container {
    display: flex;
    height: 500px;
    margin-top: 5%;
    justify-content: space-around;
}

.about-container > img {
    box-shadow: 0px 0px 85px 0px rgb(0 0 0 / 14%);
    border: 20px solid #b8a07e;
    width: 100%;
    max-width: 500px;
    height: 100%;
    object-fit: cover;
    object-position: 50% 5%;
    overflow: hidden;
    box-sizing: border-box;
}

.self-summary {
    display: flex;
    flex-direction: column;
}

.self-summary > h3 {
    font-family: 'Playfair Display', serif;
    font-size: 28px;
    color: #898989;
    line-height: 28px;
    font-weight: 300;
}

.self-summary > h5 {
    font-family: 'Poppins', helvetica;
    font-size: 15px;
    font-weight: 300;
    line-height: 20px;
    letter-spacing: 0.02em;
    color: #898989;
}

@media only screen and (max-width: 1120px) {
    .about-container {
        flex-direction: column;
        height: 30%;
        max-height: 800px;
        max-width: 450px;
        width: 90%;
        align-items: center;
    }

    .self-summary > h3 {
        margin: 0px;
        margin-top: 5%;
    }
}

@media only screen and (min-width: 1121px) {
    .self-summary {
        padding: 5%;
        max-width: 500px;
    }
}
