.pulsatingCircle{
    width:25px;
    height:25px;
    display:block;
    position:relative;
    margin-left: 15px;
  transform:scale(2,2);
    -webkit-transform:scale(2,2);
}

.firstCircle,
.secondCircle,
.thirdCircle{
    top:50%;
    left:50%;
    display:block;
    border-radius:50%;
    position:absolute;
    vertical-align:middle;
}
    
.firstCircle{
    width:10px;
    height:10px;
    margin:-5px 0 0 -5px;
}

.secondCircle{
    width:16px;
    height:16px;
    margin:-9px 0 0 -9px;
    animation:2000ms pulseIn ease-in-out infinite;
    -webkit-animation:2000ms pulseIn ease-in-out infinite;						
}

@keyframes pulseIn{
    0%{
        opacity:0;
        transform:scale(0.2,0.2);
    }
    50%{
        opacity:1;
        transform:scale(1,1);
    }
    100%{
        opacity:0;
        transform:scale(0.2,0.2);
    }
}

@-webkit-keyframes pulseIn{
    0%{
        opacity:0;
        -webkit-transform:scale(0.2,0.2);
    }
    50%{
        opacity:1;
        -webkit-transform:scale(1,1);
    }
    100%{
        opacity:0;
        -webkit-transform:scale(0.2,0.2);
    }
}
            
.thirdCircle{
    width:24px;
    height:24px;
    margin:-13px 0 0 -13px;
    animation:2000ms pulseIn 500ms ease-in-out infinite;
    -webkit-animation:2000ms pulseIn 500ms ease-in-out infinite		
}
